<template>
  <div class="charts">
    <div class="row">
      <div class="flex md6 xs12">
        <vac-card
          class="chart-widget"
          :title="$t('charts.verticalBarChart')"
        >
          <va-chart :data="verticalBarChartData" type="vertical-bar"/>
        </vac-card>
      </div>
      <div class="flex md6 xs12">
        <vac-card
          class="chart-widget"
          :title="$t('charts.horizontalBarChart')"
        >
          <va-chart :data="horizontalBarChartData" type="horizontal-bar"/>
        </vac-card>
      </div>
    </div>

    <div class="row">
      <div class="flex md12 xs12">
        <vac-card
          class="chart-widget"
          :title="$t('charts.lineChart')"
        >
          <va-chart :data="lineChartData" type="line"/>
        </vac-card>
      </div>
    </div>

    <div class="row">
      <div class="flex md6 xs12">
        <vac-card
          class="chart-widget"
          :title="$t('charts.pieChart')"
        >
          <va-chart :data="pieChartData" type="pie"/>
        </vac-card>
      </div>
      <div class="flex md6 xs12">
        <vac-card
          class="chart-widget"
          :title="$t('charts.donutChart')"
        >
          <va-chart :data="donutChartData" type="donut"/>
        </vac-card>
      </div>
    </div>
    <div class="row">
      <div class="flex md12 xs12">
        <vac-card
          class="chart-widget"
          :title="$t('charts.bubbleChart')"
        >
          <va-chart :data="bubbleChartData" type="bubble"/>
        </vac-card>
      </div>
    </div>
  </div>
</template>

<script>
import { getLineChartData } from '../../../services/data/charts/LineChartData';
import { getBubbleChartData } from '../../../services/data/charts/BubbleChartData';
import { getPieChartData } from '../../../services/data/charts/PieChartData';
import { getDonutChartData } from '../../../services/data/charts/DonutChartData';
import { getVerticalBarChartData } from '../../../services/data/charts/VerticalBarChartData';
import { getHorizontalBarChartData } from '../../../services/data/charts/HorizontalBarChartData';
import VaChart from './va-charts/VaChart';

export default {
  name: 'charts',
  components: { VaChart },

  data () {
    return {
      bubbleChartData: getBubbleChartData(this.$themes),
      lineChartData: getLineChartData(this.$themes),
      pieChartData: getPieChartData(this.$themes),
      donutChartData: getDonutChartData(this.$themes),
      verticalBarChartData: getVerticalBarChartData(this.$themes),
      horizontalBarChartData: getHorizontalBarChartData(this.$themes),
    };
  },

  methods: {
    refreshData () {
      this.lineChartData = getLineChartData(this.$themes);
    },
  },

};

</script>

<style lang="scss">
.chart-widget {
  .vac-card__body {
    height: 550px;
  }
}
</style>
